.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;

  .logo {
    height: auto;
    max-width: 40%;
    max-height: 77%;
    margin-left: 20px;

    img {
      height: 50px;
    }
  }
}

.header-container {
  height: 70px;
  .anticon-check-circle {
    font-size: 16px;
  }

  .ant-progress-line {
    margin-inline-end: 0;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;

  h4 {
    font-weight: 400;
    margin: 0;
  }
  .link {
    font-weight: 400;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    color: #00aa5c;
  }
}
.language-changer {
  margin-left: auto;
  margin-right: 20px;
  border-radius: 5px;

  .button {
    display: flex;
    align-items: center;
    background: var(--app-color);
    color: var(--inverted-color);

    .lang-label {
      font-weight: 500;
      margin-right: auto;
    }
  }

  .ant-select-arrow {
    color: var(--inverted-color);
  }

  span {
    font-weight: 500;
  }
  .ant-select-selector {
    .ant-select-selection-item {
      color: var(--inverted-color);
    }
  }

  .ant-select-arrow {
    font-size: 15px;
    height: 11px;
  }
}

.masked-input:focus {
  border-bottom: 1px solid var(--app-color) !important;
}

.masked-input {
  border-bottom: 1px solid lightgray !important;
}

.upload-next-btn-container {
  width: 100%;
  margin: 50px 0;

  .ant-btn {
    width: 100%;
    border-radius: 12px;
    height: 44px;

    span {
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      color: black;
    }
  }
}

.error-page-layout {
  gap: 40px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .description {
    padding: 0 30px;
    display: flex;
    text-align: center;
  }
  .image {
    padding: 0 30px;
  }
  .image img {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
  }
}

.markdown-content {
  font-family: inherit !important;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6,
.markdown-content p,
.markdown-content li,
.markdown-content a {
  font-family: inherit !important;
  margin-bottom: 0;
  line-height: 1.2;
}

strong {
  font-weight: bold;
}

.range-slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 20px;
  position: relative;

  &__value-banner {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    background-color: #222;
    color: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    z-index: 10;

    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #222;
    }
  }

  &__controls {
    position: relative;
    width: 100%;
    height: 24px;
    margin-top: 10px;
  }

  &__track {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
    border-radius: 3px;
    cursor: pointer;
  }

  &__fill {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #7AC98F;
    border-radius: 3px;
    height: 6px;
    z-index: 1;
  }

  &__mark {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #f0f0f0;
    z-index: 2;
    transition: background-color 0.2s, border-color 0.2s;

    &--active {
      border-color: #7AC98F;
      background-color: white;
    }

    &--selected {
      background-color: #7AC98F;
      border-color: #7AC98F;
    }
  }
}
